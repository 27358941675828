<template>
  <div>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <player-list-table
          :has-permission="hasPermission"
          :team-name="teamOneName"
          :team-image="teamOneImage"
          :player-list="teamOnePlayerList"
          :processing="processing"
          :loading="loading"
          @kick="handleKick"
          @ban="handleBan"
          @move="handleMove($event, 1)"
          @refresh="handleRefresh">
      </player-list-table>
    </a-col>
    <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
      <player-list-table
          :has-permission="hasPermission"
          :team-name="teamTwoName"
          :team-image="teamTwoImage"
          :player-list="teamTwoPlayerList"
          :processing="processing"
          :loading="loading"
          @kick="handleKick"
          @ban="handleBan"
          @move="handleMove($event, 2)"
          @refresh="handleRefresh">
      </player-list-table>
    </a-col>
  </div>
</template>

<script>
import PlayerListTable from "./PlayerListTable.vue";

export default {
  name: "playerList",
  components: {PlayerListTable},
  props: {
    hasPermission: {
      type: Boolean,
      default: false
    },
    teamOneName: {
      type: String,
      default: ""
    },
    teamOneImage: {
      type: String,
      default: ""
    },
    teamTwoName: {
      type: String,
      default: ""
    },
    teamTwoImage: {
      type: String,
      default: ""
    },
    teamOnePlayerList: {
      type: Array,
      default: () => []
    },
    teamTwoPlayerList: {
      type: Array,
      default: () => []
    },
    teamQueuePlayerList: {
      type: Array,
      default: () => []
    },
    teamSpectatorPlayerList: {
      type: Array,
      default: () => []
    },
    processing: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleKick(player) {
      this.$emit("kick", player)
    },
    handleBan(player) {
      this.$emit("ban", player)
    },
    handleMove(player, teamId) {
      this.$emit("move", player, teamId)
    },
    handleRefresh() {
      this.$emit("refresh")
    }
  }
}
</script>

<style scoped>

</style>