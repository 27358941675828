import request from "../axios";

export function queryServerApi(gameId, serverId) {
    return request({
        url: "/server/query",
        method: 'GET',
        params: {
            game_id: gameId,
            server_id: serverId,
        }
    })
}

export function batchQueryServerApi(name, modes, maps, slots, regions, types, limit) {
    return request({
        url: "/server/query/batch",
        method: 'GET',
        params: {
            name: name,
            modes: modes,
            maps: maps,
            slots: slots,
            regions: regions,
            types: types,
            limit: limit,
        }
    })
}