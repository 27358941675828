export function setToken(token) {
    localStorage.setItem("token", token)
}
export function getToken() {
    return getCookie("token");
}
export function delToken() {
    localStorage.removeItem("token")
}

export function getLoggedIn() {
    return getToken() != null;
}

export function setCookie(name, value) {
    localStorage.setItem(name, value)
}

export function getCookie(name) {
    let item = localStorage.getItem(name);
    if (item !== undefined && item !== null && item.length > 0) {
        setCookie(name, item)
        return item;
    }
    return null;
}

export function delCookies(names) {
    for (let i = 0; i < names.length; i++) {
        localStorage.removeItem(names[i])
    }
}

export function clearUserCookie() {
    delToken()
    delCookies(["id", "username", "avatar", "nickname", "status", "token"])
}