import request from "../axios";

export function kickPlayerApi(gameId, serverId, personaId, personaName, kickReason) {
    return request({
        url: "/server/management/player/kick",
        method: 'POST',
        data: {
            gameId: gameId,
            serverId: serverId,
            personaId: personaId,
            personaName: personaName,
            kickReason: kickReason,
        }
    })
}

export function addPlayerBanApi(gameId, serverId, personaId, personaName, banReason) {
    return request({
        url: "/server/management/player/ban",
        method: 'POST',
        data: {
            gameId: gameId,
            serverId: serverId,
            personaId: personaId,
            personaName: personaName,
            banReason: banReason,
        }
    })
}

export function removePlayerBanApi(gameId, serverId, personaId, personaName) {
    return request({
        url: "/server/management/player/ban",
        method: 'DELETE',
        data: {
            gameId: gameId,
            serverId: serverId,
            personaId: personaId,
            personaName: personaName,
        }
    })
}

export function addPlayerVipApi(gameId, serverId, personaId, personaName) {
    return request({
        url: "/server/management/player/vip",
        method: 'POST',
        data: {
            gameId: gameId,
            serverId: serverId,
            personaId: personaId,
            personaName: personaName,
        }
    })
}

export function removePlayerVipApi(gameId, serverId, personaId, personaName) {
    return request({
        url: "/server/management/player/vip",
        method: 'DELETE',
        data: {
            gameId: gameId,
            serverId: serverId,
            personaId: personaId,
            personaName: personaName,
        }
    })
}

export function movePlayerApi(gameId, serverId, personaId, personaName, teamId) {
    return request({
        url: "/server/management/player/move",
        method: 'POST',
        data: {
            gameId: gameId,
            serverId: serverId,
            personaId: personaId,
            personaName: personaName,
            teamId: teamId,
        }
    })
}

export function switchMapApi(gameId, serverId, mapIndex) {
    return request({
        url: "/server/management/map/switch",
        method: 'POST',
        data: {
            gameId: gameId,
            serverId: serverId,
            mapIndex: mapIndex,
        }
    })
}