<template>
  <div>
    <a-row :gutter="30">
      <a-col :xs="24" :sm="24" :md="{span: hasPermission ? 12 : 24}" :lg="{span: hasPermission ? 12 : 24}" :xl="{span: hasPermission ? 12 : 24}">
        <el-table v-loading="loading" element-loading-background="#111111" :data="memberList.filter(data => !keyword || data['personaName'].toLowerCase().includes(keyword.toLowerCase()))" :empty-text="'暂无' + name + '玩家'" height="50vh" :header-cell-style="{color: '#FFFFFF',height: '0'}" :cell-style="{color: '#FFFFFF', padding: '5px'}">
          <el-table-column prop="personaName" min-width="200">
            <template #header>
              ID<i @click="handleRefresh" style="cursor: pointer;margin-left: 15px" class="el-icon-refresh-right"></i>
            </template>
            <template v-slot="scope">
              <a-icon @click="navigateToPersonaDetail(scope['row']['personaId'])" style="cursor: pointer;color: #faad14" type="search"/><span>&nbsp;{{ scope['row']['personaName'] }}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="hasPermission" label="操作" width="140" align="center">
            <template #header>
              <el-input class="transparentElement" v-model="keyword" size="mini" placeholder="输入关键字搜索"/>
            </template>
            <template v-slot="scope">
              <el-link :disabled="!hasPermission || processing || disableRemove" type="success" @click="handleRemove(scope['row'])">移除</el-link>
            </template>
          </el-table-column>
        </el-table>
      </a-col>
      <a-col v-if="hasPermission" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-input :disabled="!hasPermission || processing || disableInput" class="transparentElement" style="margin-top: 45px;" v-model="personaName" placeholder="请输入玩家ID"/>
        <a-button :disabled="!hasPermission || processing || disableButton || personaName.length <= 0" style="height: 40px;margin-top: 10px" size="large" class="hoverButton" @click="handleAdd" ghost block>增加至{{name}}列表</a-button>
      </a-col>
    </a-row>
  </div>
</template>

<script>

export default {
  name: "memberList",
  props: {
    name: {
      type: String,
      default: ""
    },
    hasPermission: {
      type: Boolean,
      default: false
    },
    memberList: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    disableRemove: {
      type: Boolean,
      default: false
    },
    disableInput: {
      type: Boolean,
      default: false
    },
    disableButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      keyword: "",
      personaName: "",
    }
  },
  methods: {
    handleRemove(member) {
      this.$emit("remove", member)
    },
    handleRefresh() {
      this.$emit("refresh")
    },
    handleAdd() {
      if (!this.personaName) {
        this.$notify({
          title: '警告',
          message: "请输入要添加" + this.name + "的玩家ID",
          type: 'warning'
        });
        return
      }
      let member = this.memberList.find((member) => member['personaName'].toLowerCase().includes(this.personaName.toLowerCase()));
      if (member) {
        this.$notify({
          title: '警告',
          message: "玩家已在" + this.name + "列表中",
          type: 'warning'
        });
        return
      }
      if (this.personaName.toLowerCase())
      this.$emit("add", this.personaName)
    },
    navigateToPersonaDetail(personaId) {
      let routeUrl = this.$router.resolve({
        path: "/player/info",
        query: {
          personaId: personaId,
          from: 3
        }
      });
      window.open(routeUrl .href, '_blank');
    },
  }
}
</script>

<style scoped>
/* 透明输入框 */
::v-deep .transparentElement .el-input__inner {
  /* 使input框的背景变透明 */
  background-color: transparent;
  /* 使边框也变透明 */
  border-color: white;
  /* 改变获取焦点后的竖线颜色 */
  caret-color: white;
  color:#FFFFFF;
}
/* 透明输入框聚焦 */
::v-deep .transparentElement .el-input__inner:focus {
  border-color: #e6a23c;
}
::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: transparent;
  color: white;
}

/* 透明表格 */
::v-deep .el-table, ::v-deep .el-table__expanded-cell{
  background-color: transparent;
}
/* 透明表格 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  background-color: transparent;
}
::v-deep .el-table tr,
::v-deep .el-table td {
  border-bottom: none !important;
}
/* 表格滚动条 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
/* 表格滚动条 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
/* 表格鼠标移入 */
::v-deep .el-table tbody tr:hover>td {
  background: rgba(255, 255, 255, 0.2) !important;
}

/* 按钮鼠标移入聚焦 */
.hoverButton.ant-btn:hover, .hoverButton.ant-btn:focus {
  background-color: white !important;
  border-color: white !important;
  font-weight: bold;
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled]:hover, .hoverButton.ant-btn[disabled]:focus {
  color: #4f4f4f;
}
.hoverButton.ant-btn[disabled] {
  color: white;
}
</style>