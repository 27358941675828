export const Bf1Country = {
    1: "日本",
    2: "香港",
    3: "美国",
    4: "阿拉伯",
    5: "澳大利亚",
    6: "德国",
    7: "巴西"
}

export const BF1Region = {
    1: "亚洲",
    2: "大洋洲",
    3: "欧洲",
    4: "非洲",
    5: "南极洲",
    6: "南美洲",
    7: "北美洲",
    ASIA: 1,
    OC: 2,
    EU: 3,
    AFR: 4,
    AC: 5,
    SAM: 6,
    NAM: 7
}

export const Bf1ServerType = {
    1: "官服",
    2: "私服",
    3: "私服(特殊)",
    4: "私服(密码)",
}

export const BF1Faction = {
    "USA": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/10/110/FactionsColorUnitedStatesOfAmericaLarge-0a6e66a7.png",
        "name": "美利坚合众国"
    },
    "OTM": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/69/73/FactionsColorOttomanEmpireLarge-45b70933.png",
        "name": "奥斯曼帝国"
    },
    "WA": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/113/64/FactionsColorRussia-8f40526a.png",
        "name": "俄罗斯白军"
    },
    "AHU": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/30/21/FactionsColorAustroHungarianEmpireLarge-e2ebe691.png",
        "name": "奥匈帝国"
    },
    "OTT": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/69/73/FactionsColorOttomanEmpireLarge-45b70933.png",
        "name": "奥斯曼帝国"
    },
    "UKG": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/103/37/FactionsColorUnitedKingdomLarge-672527a9.png",
        "name": "大英帝国"
    },
    "RUS": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/113/64/FactionsColorRussia-8f40526a.png",
        "name": "俄罗斯帝国"
    },
    "FRA": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/93/43/FactionsColorFranceLarge-a3d5b514.png",
        "name": "法兰西共和国"
    },
    "GER": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/117/41/FactionsColorGermanEmpireLarge-8bd7e888.png",
        "name": "德意志帝国"
    },
    "UK": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/103/37/FactionsColorUnitedKingdomLarge-672527a9.png",
        "name": "大英帝国"
    },
    "UKM": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/25/95/FactionsLargeColorBritishRoyalMarines-e75f358e.png",
        "name": "皇家海军陆战队"
    },
    "ITA": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/22/73/FactionsColorKingdomOfItalyLarge-eab7049b.png",
        "name": "意大利王国"
    },
    "UNKNOWN": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/117/41/FactionsColorGermanEmpireLarge-8bd7e888.png",
        "name": "未知"
    },
    "BOL": {
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/63/106/FactionsLargeColorBolshevik-3f6ac72d.png",
        "name": "苏俄红军"
    }
}

export const BF1Mode = {
    "TugOfWar": {
        "name": "前线模式"
    },
    "TeamDeathMatch": {
        "name": "团队死斗"
    },
    "Conquest": {
        "name": "征服模式"
    },
    "Possession": {
        "name": "战争信鸽"
    },
    "Domination": {
        "name": "抢攻模式"
    },
    "Rush": {
        "name": "突袭模式"
    },
    "ZoneControl": {
        "name": "空降补给"
    },
    "AirAssault": {
        "name": "空中突袭"
    },
    "Breakthrough": {
        "name": "闪击行动"
    },
    "BreakthroughLarge": {
        "name": "行动模式"
    }
}

export const Bf1Map = {
    "MP_Ravines": {
        "name": "武普库夫山口",
        "teamTwo": BF1Faction.RUS,
        "teamOne": BF1Faction.AHU,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/31/32/MP_Ravines_LandscapeLarge-1fe0d3f6.jpg"
    },
    "MP_Harbor": {
        "name": "泽布吕赫",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.UKM,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/45/126/MP_Harbor_LandscapeLarge-d382c7ea.jpg"
    },
    "MP_Volga": {
        "name": "窝瓦河",
        "teamTwo": BF1Faction.WA,
        "teamOne": BF1Faction.BOL,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/106/60/MP_Volga_LandscapeLarge-6ac49c25.jpg"
    },
    "MP_Graveyard": {
        "name": "决裂",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.FRA,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/67/16/MP_Graveyard_LandscapeLarge-bd1012e6.jpg"
    },
    "MP_ShovelTown": {
        "name": "攻占托尔",
        "teamTwo": BF1Faction.FRA,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/48/86/MP_Shoveltown_LandscapeLarge-d0aa5920.jpg"
    },
    "MP_Islands": {
        "name": "阿尔比恩",
        "teamTwo": BF1Faction.RUS,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/55/40/MP_Islands_LandscapeLarge-c9d8272b.jpg"
    },
    "MP_Naval": {
        "name": "黑尔戈兰湾",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.UKM,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/36/46/MP_Naval_LandscapeLarge-dc2e8daf.jpg"
    },
    "MP_Ridge": {
        "name": "阿奇巴巴",
        "teamTwo": BF1Faction.OTM,
        "teamOne": BF1Faction.UK,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/116/5/MP_Ridge_LandscapeLarge-8c057a19.jpg"
    },
    "MP_Chateau": {
        "name": "流血宴厅",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.USA,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/36/77/MP_Chateau_LandscapeLarge-244d5987.jpg"
    },
    "MP_Tsaritsyn": {
        "name": "察里津",
        "teamTwo": BF1Faction.WA,
        "teamOne": BF1Faction.BOL,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/45/67/MP_Tsaritsyn_LandscapeLarge-2dbd3bf5.jpg"
    },
    "MP_Scar": {
        "name": "圣康坦的伤痕",
        "teamTwo": BF1Faction.UK,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/18/37/MP_Scar_LandscapeLarge-ee25fbd6.jpg"
    },
    "MP_Hell": {
        "name": "帕斯尚尔",
        "teamTwo": BF1Faction.ITA,
        "teamOne": BF1Faction.AHU,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/113/118/MP_Hell_LandscapeLarge-7176911c.jpg"
    },
    "MP_Verdun": {
        "name": "凡尔登高地",
        "teamTwo": BF1Faction.FRA,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/26/54/MP_Verdun_LandscapeLarge-1a364063.jpg"
    },
    "MP_Underworld": {
        "name": "法乌克斯要塞",
        "teamTwo": BF1Faction.FRA,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/74/59/MP_Underworld_LandscapeLarge-b6c5c7e7.jpg"
    },
    "MP_Desert": {
        "name": "西奈沙漠",
        "teamTwo": BF1Faction.OTM,
        "teamOne": BF1Faction.UK,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/40/9/MP_Desert_LandscapeLarge-d8f749da.jpg"
    },
    "MP_ItalianCoast": {
        "name": "帝国边境",
        "teamTwo": BF1Faction.AHU,
        "teamOne": BF1Faction.ITA,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/21/3/MP_ItalianCoast_LandscapeLarge-1503eec7.jpg"
    },
    "MP_Giant": {
        "name": "庞然暗影",
        "teamTwo": BF1Faction.UK,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/35/11/MP_Giant_LandscapeLarge-dd0b93ef.jpg"
    },
    "MP_MountainFort": {
        "name": "格拉巴山",
        "teamTwo": BF1Faction.AHU,
        "teamOne": BF1Faction.ITA,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/118/81/MP_MountainFort_LandscapeLarge-8a517533.jpg"
    },
    "MP_River": {
        "name": "卡波雷托",
        "teamTwo": BF1Faction.ITA,
        "teamOne": BF1Faction.AHU,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/33/68/MP_River_LandscapeLarge-21443ae9.jpg"
    },
    "MP_Forest": {
        "name": "阿尔贡森林",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.USA,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/33/69/MP_Forest_LandscapeLarge-dfbbe910.jpg"
    },
    "MP_FaoFortress": {
        "name": "法欧堡",
        "teamTwo": BF1Faction.OTM,
        "teamOne": BF1Faction.UK,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/54/47/MP_FaoFortress_LandscapeLarge-cad1748e.jpg"
    },
    "MP_Amiens": {
        "name": "亚眠",
        "teamTwo": BF1Faction.UK,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/31/107/MP_Amiens_LandscapeLarge-e195589d.jpg"
    },
    "MP_Suez": {
        "name": "苏伊士",
        "teamTwo": BF1Faction.OTM,
        "teamOne": BF1Faction.UK,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/10/48/MP_Suez_LandscapeLarge-f630fc76.jpg"
    },
    "MP_Valley": {
        "name": "加利西亚",
        "teamTwo": BF1Faction.AHU,
        "teamOne": BF1Faction.RUS,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/115/63/MP_Valley_LandscapeLarge-8dc1c7ca.jpg"
    },
    "MP_Blitz": {
        "name": "伦敦的呼唤:夜袭",
        "teamTwo": BF1Faction.UK,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/94/38/MP_Blitz_LandscapeLarge-5e26212f.jpg"
    },
    "MP_London": {
        "name": "伦敦的呼唤:灾祸",
        "teamTwo": BF1Faction.UK,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/11/81/MP_London_LandscapeLarge-0b51fe46.jpg"
    },
    "MP_Alps": {
        "name": "剃刀边缘",
        "teamTwo": BF1Faction.UK,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/122/77/MP_Alps_LandscapeLarge-7ab30e3e.jpg"
    },
    "MP_Offensive": {
        "name": "索姆河",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.UK,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/109/85/MP_Offensive_LandscapeLarge-6dabdea3.jpg"
    },
    "MP_Trench": {
        "name": "尼维尔之夜",
        "teamTwo": BF1Faction.FRA,
        "teamOne": BF1Faction.GER,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/37/47/MP_Trench_LandscapeLarge-dbd1248f.jpg"
    },
    "MP_Fields": {
        "name": "苏瓦松",
        "teamTwo": BF1Faction.GER,
        "teamOne": BF1Faction.FRA,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/95/83/MP_Fields_LandscapeLarge-5f53ddc4.jpg"
    },
    "MP_Beachhead": {
        "name": "海丽丝岬",
        "teamTwo": BF1Faction.OTM,
        "teamOne": BF1Faction.UK,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/90/19/MP_Beachhead_LandscapeLarge-5a13c655.jpg"
    },
    "MP_Bridge": {
        "name": "勃鲁西洛夫关口",
        "teamTwo": BF1Faction.AHU,
        "teamOne": BF1Faction.RUS,
        "image": "https://eaassets-a.akamaihd.net/battlelog/battlebinary/gamedata/Tunguska/91/127/MP_Bridge_LandscapeLarge-5b7f1b62.jpg"
    }
}

export const BF1Settings = {
    kits: {
        "精英兵种": true,
        "支援兵": true,
        "医护兵": true,
        "侦察兵": true,
        "突击兵": true
    },
    misc: {
        "瞄准辅助自动转向": true,
        "载入填满弹匣": false,
        "无上限弹匣": false,
        "3D 索敌": true,
        "载具第三人称视角": true,
        "击杀镜头": true,
        "自动回复生命值": true,
        "机械巨兽": true,
        "停用回合前就绪阶段": false,
        "友军砲火": false,
        "抬头显示": true,
        "允许较高延迟": false,
        "瞄准辅助减速": true,
        "地图投票": true,
        "名牌": true,
        "只能在小队长附近重生": false,
        "停用队伍平衡": false,
        "延迟锁定": false,
        "迷你地图标记": true,
        "永久迷雾": false,
        "停用观众延迟": false
    },
    scales: {
        "回合时限": 0,
        "载具重置时间": 100,
        "子弹伤害": 100,
        "兵力值": 100,
        "重生时间": 100
    },
    vehicles: {
        "地面载具": true,
        "空中载具": true
    },
    weapons: {
        "爆裂物": true,
        "兵种配备": true,
        "单动式步枪": true,
        "霰弹枪": true,
        "手枪": true,
        "冲锋枪": true,
        "近战武器": true,
        "轻机枪": true,
        "半自动步枪": true,
        "制式步枪": false
    }
}

export const BF1Kit = {
    1: {
        name: '突击兵',
        images: {
            white: 'https://pic.imgdb.cn/item/65367dccc458853aef0e1668.png',
            black: 'https://pic.imgdb.cn/item/65367e2dc458853aef0f8695.png',
        }
    },
    2: {
        name: '医疗兵',
        images: {
            white: 'https://pic.imgdb.cn/item/65367e5cc458853aef103bc4.png',
            black: 'https://pic.imgdb.cn/item/65367e8cc458853aef10eefe.png',
        }
    },
    3: {
        name: '支援兵',
        images: {
            white: 'https://pic.imgdb.cn/item/65367eadc458853aef1169ae.png',
            black: 'https://pic.imgdb.cn/item/65367eb9c458853aef119696.png',
        }
    },
    4: {
        name: '侦察兵',
        images: {
            white: 'https://pic.imgdb.cn/item/65367ec9c458853aef11d071.png',
            black: 'https://pic.imgdb.cn/item/65367ed5c458853aef11ffc7.png',
        }
    },
    5: {
        name: '骑兵',
        images: {
            white: 'https://pic.imgdb.cn/item/65368641c458853aef2f8a89.png',
            black: 'https://pic.imgdb.cn/item/65368556c458853aef2bdaa7.png',
        }
    },
    6: {
        name: '飞行员',
        images: {
            white: 'https://pic.imgdb.cn/item/65368660c458853aef3009a0.png',
            black: 'https://pic.imgdb.cn/item/6536856ec458853aef2c3865.png',
        }
    },
    7: {
        name: "驾驶员",
        images: {
            white: 'https://pic.imgdb.cn/item/6536867ac458853aef307038.png',
            black: 'https://pic.imgdb.cn/item/65368574c458853aef2c5099.png',
        }
    }
}